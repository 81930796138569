import React, { Component, ReactNode } from 'react';

import { computed, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './AcceptTermsModal.module.scss';

import AntModal from 'app/components/ui/AntModal';
import { ModalAction } from 'app/components/ui/AntModal/AntModal';
import { PRIVACY_POLICY_URL, STORE_MEMBER } from 'app/constants';
import { MemberModel } from 'app/models';
import { MemberStore, MemberStoreIncludes } from 'app/stores';

interface AcceptTermsModalProps {
  memberStore?: MemberStore;
}

export class AcceptTermsModal extends Component<AcceptTermsModalProps> {
  @observable isModalOpen = false;
  @observable setIsModalOpen = (status: boolean) => (this.isModalOpen = status);

  @observable isSubmitButtonDisabled = false;
  @observable setIsSubmitButtonDisabled = (status: boolean) =>
    (this.isSubmitButtonDisabled = status);

  constructor(props: AcceptTermsModalProps) {
    super(props);
    this.init();
  }

  init = (): void => {
    if (this.currentMember && this.currentMember.accepted_current_policy) {
      return;
    }

    this.loadCurrentMember();

    // Show modal only to people who have logged in via session login
    when(
      () => !!this.currentMember?.is_session_login,
      () => {
        this.setIsModalOpen(!this.currentMember.accepted_current_policy);
      }
    );
  };

  loadCurrentMember = (): void => {
    this.props.memberStore.loadCurrentMember(
      true,
      [MemberStoreIncludes.ORGANIZATIONS, MemberStoreIncludes.ORGANIZATIONS_TEAM_GROUPS],
      true,
      false
    );
  };

  get currentMember(): MemberModel {
    return this.props.memberStore.currentMember.item;
  }

  get title(): ReactNode {
    return (
      <div className={styles.title}>
        <img src="/images/logo-only.svg" alt="valence-logo" />
        <h5>We’ve updated our Privacy Policy</h5>
      </div>
    );
  }

  get content(): ReactNode {
    return (
      <div className={styles.modalContent}>
        <p>
          Please review and accept our updated &nbsp;
          <a target="_blank" href={PRIVACY_POLICY_URL}>
            Privacy Policy
          </a>
          &nbsp;to continue using Valence.
        </p>
      </div>
    );
  }

  @computed
  get primaryAction(): ModalAction {
    return {
      label: 'Accept and continue',
      disabled: this.isSubmitButtonDisabled,
      onClick: this.handleAcceptPolicy,
      id: 'accept-policy-button',
    };
  }

  handleAcceptPolicy = async (): Promise<void> => {
    this.currentMember.updateFromJson({ accepted_current_policy: true });

    this.setIsSubmitButtonDisabled(true);
    await this.props.memberStore.updateMember(this.currentMember);
    this.setIsSubmitButtonDisabled(false);
    this.setIsModalOpen(false);
  };

  render(): ReactNode {
    return (
      <AntModal isOpen={this.isModalOpen} title={this.title} primaryAction={this.primaryAction}>
        {this.content}
      </AntModal>
    );
  }
}

export default inject(STORE_MEMBER)(observer(AcceptTermsModal));
