import { notification } from 'antd';
import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList } from 'app/models';
import AnalyticsFilterModel from 'app/models/AnalyticsFilterModel';

import Store from './Store';

export class AnalyticsFiltersStore extends Store<AnalyticsFilterModel> {
  @observable filters = new ModelList<AnalyticsFilterModel>(AnalyticsFilterModel);
  @observable isLoading = false;

  constructor() {
    super();
    AnalyticsFilterModel._store = this;
  }

  @action
  async loadByOrgId(orgId: number): Promise<void> {
    const config = {
      url: ServerRouteHelper.api.organizations.analyticsFilters(orgId),
    };

    this.isLoading = true;

    try {
      const resp = await this.apiService.newGet(config);

      this.filters.deserialize(resp.data);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Unable to fetch filters',
      });
    } finally {
      this.isLoading = false;
    }
  }
}

export default AnalyticsFiltersStore;
