import { notification } from 'antd';
import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList } from 'app/models';
import ContractModel from 'app/models/ContractModel';

import Store from './Store';

export class ContractStore extends Store<ContractModel> {
  @observable contracts = new ModelList<ContractModel>(ContractModel);

  constructor() {
    super();
    ContractModel._store = this;
  }

  @action
  async loadByOrgId(orgId: number): Promise<void> {
    try {
      const url = ServerRouteHelper.api.organizations.contracts(orgId);

      const response = await this.apiService.get(url);

      this.contracts.setItems(response.data.contracts);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Unable to fetch contracts',
      });
    }
  }
}

export default ContractStore;
