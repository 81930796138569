import { notification } from 'antd';
import { pick, reduce } from 'lodash';
import { action, observable, runInAction } from 'mobx';

import { ClientDataHelper, ServerRouteHelper } from 'app/helpers';
import {
  AlignModel,
  ExerciseTypeModel,
  MemberModel,
  ModelItem,
  OrganizationModel,
  PagingInfoModel,
  PagingMetaModel,
  PulseTemplateModel,
} from 'app/models';
import { ModelList } from 'app/models/ModelList';

import Store from './Store';

export class OrganizationStore extends Store<OrganizationModel> {
  @observable public organization = new ModelItem<OrganizationModel>(OrganizationModel);
  @observable public organizationsList = new ModelList<OrganizationModel>(OrganizationModel);

  @observable public exercisesByType: { [exerciseTypeId: number]: AlignModel[] };
  @observable public exerciseTypesUsed: ExerciseTypeModel[];

  @observable public exerciseLoading = false;

  @observable public searchAllMembers = new ModelList<MemberModel>(MemberModel);
  @observable public searchMembersPage = new ModelList<MemberModel>(MemberModel);
  @observable public searchMembersPageMeta: PagingMetaModel;

  constructor() {
    super();
    OrganizationModel._store = this;
  }

  // Any methods in stores/models starting with load:
  // 1. denote GET request our APIs, ret are synchronous with
  // 2. Trigger asynchronous operations but are synchronous themselves
  // 3. return void
  // 4. In contrast store/model methods doing POST/PUT/PATCH are async and return promise of corresponding Model/Model[]
  // 5. store/model methods doing DELETE are also async and return promise of void
  @action
  public async loadOrganization() {
    console.warn('loadOrganization is deprecated, use getOrganizationById instead');

    if (this.organization.item || this.organization.loading) {
      return;
    }
    this.organization.loading = true;
    this.organization.error = null;

    await ClientDataHelper.getOrganization().then((organization) => {
      try {
        const orgModel = OrganizationModel.fromJson(organization);
        runInAction(() => {
          this.organization.loading = false;
          this.organization.setItem(orgModel);
        });
      } catch (e) {
        runInAction(() => {
          this.organization.error = e;
          this.organization.loading = false;
        });
      }
    });
  }

  @action
  public searchMembers = async (
    orgId: number,
    pageInfo?: Partial<PagingInfoModel>
  ): Promise<void> => {
    const url = ServerRouteHelper.api.organizations.searchMembers(orgId);
    const response = await this.searchMembersPage.load(url, pageInfo);

    if (!response) {
      return;
    }

    // If we at first page, clear existing aggregated members
    if (pageInfo?.page === 1) {
      this.searchAllMembers.setItems([]);
    }

    this.searchAllMembers.appendItems(this.searchMembersPage.items);
    this.searchMembersPageMeta = new PagingMetaModel(response.meta);
  };

  @action
  public loadOrgBySecretCode(secretCode: string) {
    const url = ServerRouteHelper.api.organizations.getBySecretCode(secretCode);

    return this.apiService.get(url);
  }

  async getOrganizationById(id: number) {
    const url = ServerRouteHelper.api.organizations.show(id);
    await this.organization.load(url, null, { dataKey: 'organization' });
  }

  async loadAllOrganizations() {
    const url = ServerRouteHelper.api.organizations.list();
    await this.organizationsList.load(url, null, { dataKey: 'organizations' });
  }

  @action
  async loadMembers(orgId: number, params: { [key: string]: any }, includes: string[] = []) {
    const organization = OrganizationModel.getOrNew(orgId);
    await organization.members.load(ServerRouteHelper.api.organizations.members(organization.id), {
      ...params,
      includes: includes.join(','),
    });
  }

  @action
  async loadTeams(orgId: number, params: { [key: string]: any }, includes: string[] = []) {
    const organization = OrganizationModel.getOrNew(orgId);
    await organization.teams.load(ServerRouteHelper.api.organizations.teams(organization.id), {
      ...params,
      includes: includes.join(','),
    });
  }

  @action
  async updateOrganization(orgID: number, input: Partial<OrganizationModel>) {
    const org = OrganizationModel.getOrNew(orgID);
    const url = ServerRouteHelper.api.organizations.update(org.id);
    const data = { organization: { ...input } };
    const response = await this.apiService.put(url, data);

    org.updateFromJson(response);
  }

  @action
  async updateLogo(organization: OrganizationModel, formData: FormData) {
    const url = ServerRouteHelper.api.admin.organizations.logo(organization.id);
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = await this.apiService.post(url, formData, headers);

    organization.updateFromJson(response);
  }

  updateEmailDomains(orgId: number, domains: string[]): Promise<void> {
    const url = ServerRouteHelper.api.admin.organizations.emailDomains(orgId);
    const config = {
      url,
      data: { domains },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  updateSecretCode(orgId: number, code: string): Promise<void> {
    const url = ServerRouteHelper.api.admin.organizations.secretCode(orgId);
    const config = {
      url,
      data: { code },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  @action
  async createTeams(orgId: number, data) {
    const url = ServerRouteHelper.api.organizations.teams(orgId);
    const config = {
      url,
      data,
      throwError: true,
    };

    await this.apiService.newPost(config);
  }

  @action
  async removeMember(orgId: number, memberId: number) {
    const organization = OrganizationModel.getOrNew(orgId);
    await this.apiService.delete(ServerRouteHelper.api.organizations.member(orgId, memberId));
    organization.members.setItems(
      organization.members.items.filter((item) => item.id !== memberId)
    );
  }
  @action
  async updateMemberRole(orgId: number, memberId: number, role: string) {
    const url = ServerRouteHelper.api.organizations.member(orgId, memberId);
    await this.apiService.put(url, { role });
  }

  @action
  async loadPulses(orgId: number) {
    const organization = OrganizationModel.getOrNew(orgId);
    const url = ServerRouteHelper.api.organizations.pulses(organization.id);

    await organization.pulses.load(url);
  }

  async getOrganizationStats(organization: OrganizationModel) {
    const url = ServerRouteHelper.api.organizations.stats(organization.id);
    await organization.organizationStats.load(url, null, { dataKey: null });
  }

  @action
  async loadUsedExerciseTypes() {
    if (this.exerciseLoading) {
      return;
    }

    this.exerciseLoading = true;
    this.exerciseTypesUsed = await ClientDataHelper.get('exerciseTypesUsed');
    this.exercisesByType = await ClientDataHelper.get('exercisesByType');
    this.exerciseLoading = false;
  }

  @action
  async saveExerciseTypes(orgId: number, exerciseTypes: ExerciseTypeModel[]) {
    const exercise_type_ids = exerciseTypes.map((exerciseType) => exerciseType.id);
    const url = ServerRouteHelper.api.admin.organizations.saveExerciseTypes(orgId);

    await this.apiService.post(url, { exercise_type_ids });
  }

  @action
  async saveFeaturedExerciseTypes(orgId: number, exerciseTypes: ExerciseTypeModel[]) {
    const data = reduce(
      exerciseTypes,
      (acc, exerciseType) => {
        acc[exerciseType.id] = pick(exerciseType, ['label', 'order', 'style']);
        return acc;
      },
      {}
    );

    const url = ServerRouteHelper.api.admin.organizations.saveFeaturedExerciseTypes(orgId);
    await this.apiService.post(url, data);
  }

  @action
  async savePulseTemplates(orgId: number, templates: PulseTemplateModel[]): Promise<void> {
    const templateIds = templates.map((template) => template.id);
    const url = ServerRouteHelper.api.admin.pulses.templates.saveOrgTemplates(orgId);

    try {
      const config = {
        url,
        data: { templateIds },
        throwError: true,
      };

      await this.apiService.newPost(config);

      notification.success({
        message: 'Pulse Templates',
        description: 'Successfully saved pulse templates',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Pulse Templates',
        description: 'Unable to save pulse templates, we are looking into it.',
        placement: 'bottomRight',
      });
    }
  }

  addMembers(orgId: number, members: { email: string; role: string }[]): Promise<void> {
    const url = ServerRouteHelper.api.organizations.addMembers(orgId);

    const config = {
      url,
      data: { members },
      throwError: true,
    };

    return this.apiService.newPost(config);
  }

  async addMemberToNewOrg(data: { name: string; role: string }) {
    const url = ServerRouteHelper.api.organizations.addMemberToNewOrg();

    const config = {
      url,
      data,
      throwErrow: true,
    };

    const response = await this.apiService.newPost(config);

    return OrganizationModel.fromJson(response.data);
  }

  async anonymizeMember(memberId: number, organizationId: number): Promise<void> {
    const url = ServerRouteHelper.api.organizations.anonymize(memberId, organizationId);
    const config = {
      url,
      throwError: true,
    };

    try {
      await this.apiService.newPost(config);

      notification.success({
        message: 'Success',
        description: 'Member successfully deleted.',
        placement: 'bottomRight',
        duration: 4,
      });
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'An error occured while trying to delete the user',
        placement: 'bottomRight',
        duration: 4,
      });
    }
  }
}
