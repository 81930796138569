import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { MemberModel, PulseForTeamMeta, PulseForTeamModel, PulseStatementModel } from 'app/models';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

export class PulseForTeamStore extends Store<PulseForTeamModel> {
  @observable pulse = new ModelItem<PulseForTeamModel>(PulseForTeamModel);
  @observable pulses = new ModelList<PulseForTeamModel>(PulseForTeamModel);

  @observable isSaving = false;
  @action setIsSaving = (isSaving: boolean): void => {
    this.isSaving = isSaving;
  };

  constructor() {
    super();
    PulseForTeamModel._store = this;
  }

  loadPulseByToken = (token: string) =>
    this.pulse.load(ServerRouteHelper.api.pulses.pulseByToken(token));

  loadPulse = (teamId: number, pulseId: number) =>
    this.pulse.load(ServerRouteHelper.api.teams.pulses.show(teamId, pulseId));

  loadPulses = (teamId: number, forceRefresh = false): Promise<void> => {
    return this.pulses.load(ServerRouteHelper.api.teams.pulses.list(teamId), { forceRefresh });
  };

  // Consider for deprecation
  updatePulse = async (
    teamId: number,
    pulseId: number,
    data: Partial<PulseForTeamModel>
  ): Promise<void> => {
    const pulse = PulseForTeamModel.getOrNew(pulseId);
    const url = ServerRouteHelper.api.teams.pulses.show(teamId, pulseId);
    const response = await this.apiService.patch(url, data);
    pulse.updateFromJson(response.data);
  };

  launch = async (teamId: number, pulseId: number, started = true): Promise<PulseForTeamModel> => {
    this.setIsSaving(true);

    try {
      const pulse = PulseForTeamModel.getOrNew(pulseId);
      const url = ServerRouteHelper.api.pulses.team.updateStatus(teamId, pulseId);

      const config = {
        url,
        data: { started: started },
        throwError: true,
        showGenericError: true,
      };

      const response = await this.apiService.newPatch(config);

      if (response) {
        pulse.updateFromJson(response.data);
        return pulse;
      }
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  create = async (
    teamId: number,
    statements?: Partial<PulseStatementModel>[],
    habitName?: string,
    meta?: PulseForTeamMeta
  ): Promise<PulseForTeamModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.team.create(teamId);

      const config = {
        url,
        data: {
          name: habitName,
          statements: statements,
          meta: meta,
        },
        throwError: true,
      };

      const response = await this.apiService.newPost(config);
      return PulseForTeamModel.fromJson(response.data);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  update = async (
    pulse: PulseForTeamModel,
    statements: Partial<PulseStatementModel>[]
  ): Promise<PulseForTeamModel> => {
    this.setIsSaving(true);

    try {
      const teamId = pulse.team.item.id;
      const url = ServerRouteHelper.api.pulses.team.update(teamId, pulse.id);

      const config = {
        url,
        data: { statements: statements },
        throwError: true,
      };
      const response = await this.apiService.newPatch(config);

      if (response?.data) {
        pulse.updateFromJson(response.data);
      }

      return pulse;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  updateStatus = async (pulse: PulseForTeamModel, status: boolean): Promise<PulseForTeamModel> => {
    this.setIsSaving(true);

    try {
      const teamId = pulse.team.item.id;
      const url = ServerRouteHelper.api.pulses.team.updateStatus(teamId, pulse.id);

      const config = {
        url,
        data: { started: status },
        throwError: true,
      };
      const response = await this.apiService.newPatch(config);
      pulse.updateFromJson(response.data);

      return pulse;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  updateParticipants = async (
    pulse: PulseForTeamModel,
    participants: Partial<MemberModel>[]
  ): Promise<PulseForTeamModel> => {
    this.setIsSaving(true);

    try {
      const url = ServerRouteHelper.api.pulses.team.participants(pulse.team.item.id, pulse.id);

      const config = {
        url,
        data: {
          participants: participants,
        },
        throwError: true,
        showGenericError: true,
      };

      const response = await this.apiService.newPut(config);

      if (!response?.data) {
        throw new Error('Failed to update pulse participants');
      }

      pulse.team.item.members.appendUniqueItems(response.data);
      pulse.participants.deserialize(response.data);

      return pulse;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };

  reminder = async (pulse: PulseForTeamModel): Promise<void> => {
    try {
      const url = ServerRouteHelper.api.pulses.team.reminder(pulse.team.item.id, pulse.id);

      const config = {
        url,
      };

      await this.apiService.newPost(config);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }
  };

  delete = async (pulse: PulseForTeamModel): Promise<void> => {
    try {
      const url = ServerRouteHelper.api.pulses.team.delete(pulse.team.item.id, pulse.id);

      const config = {
        url,
      };

      await this.apiService.newDelete(config);
      this.pulses.deleteItem(pulse);
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    }
  };

  togglePause = async (pulse: PulseForTeamModel, paused: boolean): Promise<PulseForTeamModel> => {
    this.setIsSaving(true);

    try {
      const teamId = pulse.team.item.id;
      const url = ServerRouteHelper.api.pulses.team.updateStatus(teamId, pulse.id);

      const config = {
        url,
        data: { disabled: paused },
        showGenericError: true,
      };

      const response = await this.apiService.newPatch(config);

      if (response?.data) {
        pulse.updateFromJson(response.data);
      }

      return pulse;
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);
    } finally {
      this.setIsSaving(false);
    }
  };
}

export default PulseForTeamStore;
